import { Injectable } from '@angular/core';
import { UserRestService} from './user-rest.service';
import 'rxjs/add/operator/toPromise';
import 'rxjs/Rx';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable()
export class UserService {
  constructor(private userRestService: UserRestService, private router: Router) {
  }

  /**
   * create new User
   * @param apiUrl api url name
   * @param data data request
   */
  public createUser(apiUrl: string, data: any) {
    return this.userRestService.createUser(apiUrl, data)
      .then((res: any) => res);
  }

  /**
   * Update User by id .
   * @param apiUrl api url name
   * @param data data request
   */
  public updateUser(apiUrl: string, data: any) {
    return this.userRestService.updateUser(apiUrl, data)
      .then((res: any) => res);
  }

  /**
   * Get User by id
   * @param apiUrl api url name
   */
  public getUserById(apiUrl: string) {
    return this.userRestService.getUserById(apiUrl)
      .then((res: any) => res);
  }

  /**
   * Get City by stateId
   * @param apiUrl api url name
   */
  public getCityByStateId(apiUrl: string) {
    return this.userRestService.getCityByStateId(apiUrl)
      .then((res: any) => res);
  }

  public getMenuByRole(apiUrl: string) {
    return this.userRestService.getMenuByRole(apiUrl)
      .then((res: any) => res);
  }
  /**
   * Function to get all Users
   * @param apiUrl  api url name
   */
  public getUserList(apiUrl: string) {
    return this.userRestService.getUserList(apiUrl)
      .then((res: any) => res);
  }

  /**
   * Function to get all state list
   * @param apiUrl  api url name
   */
  public getAllStateList(apiUrl: string) {
    return this.userRestService.getAllStateList(apiUrl)
      .then((res: any) => res);
  }

  /**
   * Function to deletes Users
   * @param apiUrl  api url name
   */
  public deleteUser(apiUrl: string) {
    return this.userRestService.delete(apiUrl)
      .then((res: any) => res);
  }

  /**
   * Get employmentTypes by scheduleId
   * @param apiUrl api url name
   */
  public getEmployementTypeByScheduleId(apiUrl: string) {
    return this.userRestService.getEmployementTypeByScheduleId(apiUrl)
      .then((res: any) => res);
  }

  /**
   * Get outlet schedule by outletId
   * @param apiUrl api url name
   */
  public getScheduleByOutletId(apiUrl: string) {
    return this.userRestService.getScheduleByOutletId(apiUrl)
      .then((res: any) => res);
  }

  /**
   * Get outlet services by outletId
   * @param apiUrl api url name
   */
  public getOutletServiceByOutletId(apiUrl: string) {
    return this.userRestService.getOutletServiceByOutletId(apiUrl)
      .then((res: any) => res);
  }

  /**
   * Get outlet shift by scheduleIds
   * @param apiUrl api url name
   */
  public getShiftListBySchedule(apiUrl: string, data: any) {
    return this.userRestService.getShiftListBySchedule(apiUrl, data)
      .then((res: any) => res);
  }

  public getRoleList(apiUrl: string) {
    return this.userRestService.getRoleList(apiUrl)
      .then((res: any) => res);
  }

  /**
   * Function to check email exist or not
   * @param apiUrl api url
   * @param data request data
   */
  public checkEmailExist(apiUrl: string, data: any) {
    return this.userRestService.checkEmailExist(apiUrl, data)
      .then((res: any) => res);
  }


    /**
   * Function to validate member trail coupon
   * @param apiUrl api url
   * @param data request data
   */
     public validateMemberTrailCoupon(apiUrl: string, data: any) {
      return this.userRestService.validateMemberTrailCoupon(apiUrl, data)
        .then((res: any) => res);
    }

  /**
   * Get User by id
   * @param apiUrl api url name
   */

  public deleteUserById(apiUrl: string) {
    return this.userRestService.delete(apiUrl)
    .then((res: any) => res);
  }

  /**
   * To accept reject user
   * @param apiUrl api url
   * @param data data
   */

 public acceptRejectUser(apiUrl: string, data: any) {
   return this.userRestService.acceptRejectUser(apiUrl, data)
     .then((res: any) => res);
 }

  /**
   * Function to block unblock user
   * @param apiUrl api url
   * @param data data
   */

  public blockUnblockUser(apiUrl: string, data: any) {
    return this.userRestService.acceptRejectUser(apiUrl, data)
      .then((res: any) => res);
  }

  /**
   * Function to check email exist or not
   * @param apiUrl api url
   * @param data request data
   */
  public sendInvitation(apiUrl: string, data: any) {
    return this.userRestService.sendInvitation(apiUrl, data)
      .then((res: any) => res);
  }

  /**
   * create new Employee
   * @param apiUrl api url name
   * @param data data request
   */
  public createEmployee(apiUrl: string, data: any) {
    return this.userRestService.createEmployee(apiUrl, data)
      .then((res: any) => res);
  }

  /**
   * Update Employee by id .
   * @param apiUrl api url name
   * @param data data request
   */
  public updateEmployee(apiUrl: string, data: any) {
    return this.userRestService.updateEmployee(apiUrl, data)
      .then((res: any) => res);
  }
  /**
   * Function to get outlet list
   * @param apiUrl api url
   * @param data request data
   */
  public getOutletList(apiUrl: string) {
    return this.userRestService.getOutletList(apiUrl)
      .then((res: any) => res);
  }
  /**
   * Function to get all employee by schedule id
   * @param apiUrl  requested api url
   */
  public getEmployeeListByOutletId(apiUrl: string) {
    return this.userRestService.getEmployeeListByOutletId(apiUrl)
      .then((res: any) => res);
  }

  /**
   * Get User Status by id
   * @param apiUrl api url name
   */
  public getUserStatusById(apiUrl: string) {
    return this.userRestService.getUserStatusById(apiUrl)
      .then((res: any) => res);
  }

  /**
   * Function to create event
   * @param apiUrl api url
   * @param data request data
   */
  public createEvent(apiUrl: string, data: any) {
    return this.userRestService.createEvent(apiUrl, data)
      .then((res: any) => res);
  }

  /**
   * Function to get all employee Detail by slot id
   * @param apiUrl  requested api url
   */
  public getEmployeeDetailBySlottId(apiUrl: string) {
    return this.userRestService.getEmployeeDetailBySlottId(apiUrl)
      .then((res: any) => res);
  }


  /**
   * Update customer booking attendence .
   * @param apiUrl api url name
   * @param data data request
   */
  public updateCustomerBookingAttendence(apiUrl: string, data: any) {
    return this.userRestService.updateCustomerBookingAttendence(apiUrl, data)
      .then((res: any) => res);
  }

  /**
   * Function to create guset invitation
   * @param apiUrl api url
   * @param data request data
   */
  public createGuestInvitation(apiUrl: string, data: any) {
    return this.userRestService.createGuestInvitation(apiUrl, data)
      .then((res: any) => res);
  }

  /**
   * Update guest invitation by id .
   * @param apiUrl api url name
   * @param data data request
   */
  public updateGuestInvitation(apiUrl: string, data: any) {
    return this.userRestService.updateGuestInvitation(apiUrl, data)
      .then((res: any) => res);
  }

   /**
   * Function to get all customer count list
   * @param apiUrl  api url name
   */
  public getAllCustomerCount(apiUrl: string, data: any) {
    return this.userRestService.getAllCustomerCount(apiUrl, data)
      .then((res: any) => res);
  }

  /**
   * Function to get all invite count list
   * @param apiUrl  api url name
   */
  public getAllInviteCount(apiUrl: string, data: any) {
    return this.userRestService.getAllInviteCount(apiUrl, data)
      .then((res: any) => res);
  }

  /**
   * Function to get all invite count list
   * @param apiUrl  api url name
  */
    public getTrialMemberCount(apiUrl: string, data: any) {
    return this.userRestService.getTrailMemberCount(apiUrl, data)
      .then((res: any) => res);
  }

   /**
   * Function to get all employee services by employee id
   * @param apiUrl  requested api url
   */
  public getServicesListByEmployeeId(apiUrl: string) {
    return this.userRestService.getServicesListByEmployeeId(apiUrl)
      .then((res: any) => res);
  }

  /**
   * get timng details
   * @param apiUrl api url name
   * @param data data request
   */
  public getEmployeeTimingDetails(apiUrl: string, data: any) {
    return this.userRestService.createEmployee(apiUrl, data)
      .then((res: any) => res);
  }

  /**
   * Function to get nominated customers list
   * @param apiUrl api url
   * @param data request data
   */
  public getNominatedCustomerList(apiUrl: string) {
    return this.userRestService.getNominatedCustomerList(apiUrl)
      .then((res: any) => res);
  }

  /**
   * send email notification
   * @param apiUrl api url name
   * @param data data request
   */
  public sendNotification(apiUrl: string, data: any) {
    return this.userRestService.sendNotification(apiUrl, data)
      .then((res: any) => res);
  }

  /**
   * Function to get all member deatils send email notification 
   * @param apiUrl  api url name
   */
  public getMemberDetails(apiUrl: string, data: any) {
    return this.userRestService.getMemberDetails(apiUrl, data)
      .then((res: any) => res);
  }

  /**
   * Function to create inventory
   * @param apiUrl api url
   * @param data request data
   */
  public createInventory(apiUrl: string, data: any) {
    return this.userRestService.createInventory(apiUrl, data)
      .then((res: any) => res);
  }

  /**
   * Get Inventory by id
   * @param apiUrl api url name
   */
  public getInventoryById(apiUrl: string) {
    return this.userRestService.getInventoryById(apiUrl)
      .then((res: any) => res);
  }

  /**
   * Update Inventory by id .
   * @param apiUrl api url name
   * @param data data request
   */
  public updateInventory(apiUrl: string, data: any) {
    return this.userRestService.updateInventory(apiUrl, data)
      .then((res: any) => res);
  }

  /**
   * Function to create inventory POS
   * @param apiUrl api url
   * @param data request data
   */
  public createInventoryPos(apiUrl: string, data: any) {
    return this.userRestService.createInventoryPos(apiUrl, data)
      .then((res: any) => res);
  }

   /**
   * Function to get all inventory items
   * @param apiUrl  api url name
   */
  public getInventoryItemsList(apiUrl: string) {
    return this.userRestService.getInventoryItemsList(apiUrl)
      .then((res: any) => res);
  }

     /**
   * Function to get all inventory items
   * @param apiUrl  api url name
   */
    public getMemberPOSList(apiUrl: string) {
      return this.userRestService.getMemberPOSDataList(apiUrl)
        .then((res: any) => res);
    }

  /**
   * Get Inventory Pos by id
   * @param apiUrl api url name
   */

  public deleteInventoryPosById(apiUrl: string) {
    return this.userRestService.delete(apiUrl)
    .then((res: any) => res);
  }

  /**
   * Function to get employee slots by service id
   * @param apiUrl  requested api url
   */
  public getEmployeeTimingSlotsByService(apiUrl: string, data: any) {
    return this.userRestService.getEmployeeTimingSlotsByService(apiUrl, data)
      .then((res: any) => res);
  }

   /**
   * Get Guest by guest id
   * @param apiUrl api url name
   */
  public getGuestByGuestId(apiUrl: string) {
    return this.userRestService.getGuestByGuestId(apiUrl)
      .then((res: any) => res);
  }

  /**
   * Update substitute .
   * @param apiUrl api url name
   * @param data data request
   */
  public updateSubstitute(apiUrl: string, data: any) {
    return this.userRestService.updateSubstitute(apiUrl, data)
      .then((res: any) => res);
  }

  /**
   * Delete Inventory by id
   * @param apiUrl api url name
   */

  public deleteInventoryById(apiUrl: string) {
    return this.userRestService.delete(apiUrl)
    .then((res: any) => res);
  }

  /**
   * get outlet service booking list
   * @param data data request
   */
  public getOutletServiceBookingList(apiUrl: string, data: any) {
    return this.userRestService.getOutletServiceBookingList(apiUrl, data)
      .then((res: any) => res);
  }

   /**
   * Get outlet services by outletIds
   * @param apiUrl api url name
   */
  public getServiceListByOutletId(apiUrl: string, data: any) {
    return this.userRestService.getServiceListByOutletId(apiUrl, data)
      .then((res: any) => res);
  }

   /**
   * Get service providers by serviceIds
   * @param apiUrl api url name
   */
  public getProviderListByServiceId(apiUrl: string) {
    return this.userRestService.getProviderListByServiceId(apiUrl)
      .then((res: any) => res);
  }

  /**
   * Get Booking Slots list.
   * @param apiUrl requested api url
   * @param data requested data
   */
  public getBookingSlots(apiUrl: string, data: any) {
    return this.userRestService.getBookingSlots(apiUrl, data)
      .then((res: any) => res);
  }

  /**
   * Create Booking Slots.
   * @param apiUrl requested api url
   * @param data requested data
   */
  public createBookSlots(apiUrl: string, data: any) {
    return this.userRestService.createBookSlots(apiUrl, data)
      .then((res: any) => res);
  }

  /**
   * Function to remove attendies by booking id
   * @param apiUrl  requested api url
   */
  public removeAttendiesByBookingId(apiUrl: string, data: any) {
    return this.userRestService.removeAttendiesByBookingId(apiUrl, data)
      .then((res: any) => res);
  }
  /**
   * Function to cancel slot by slot id
   * @param apiUrl  requested api url
   */
  public cancelSlotBySlotId(apiUrl: string) {
    return this.userRestService.cancelSlotBySlotId(apiUrl)
      .then((res: any) => res);
  }

 /**
   * Get upcoming booking list
   * @param apiUrl api url name
   */
  public getUpcomingBookingList(apiUrl: string, data: any) {
    return this.userRestService.getUpcomingBookingList(apiUrl, data)
      .then((res: any) => res);
  } 
   
 /**
   * Get upcoming booking list
   * @param apiUrl api url name
   */
  public getPastBookingList(apiUrl: string, data: any) {
    return this.userRestService.getPastBookingList(apiUrl, data)
      .then((res: any) => res);
  } 

  /**
   * Function to get email host list
   * @param apiUrl api url
   * @param data request data
   */
  public getEmailHostList(apiUrl: string) {
    return this.userRestService.getEmailHostList(apiUrl)
      .then((res: any) => res);
  }

  /**
   * Function to remove waitlist by booking id
   * @param apiUrl  requested api url
   */
  public removeWaitingUserByBookingId(apiUrl: string, data: any) {
    return this.userRestService.removeWaitingUserByBookingId(apiUrl, data)
      .then((res: any) => res);
  }

  /**
   * Function to reset user password
   * @param apiUrl  api url name
   */
  public resetPassword(apiUrl: string, data: any) {
    return this.userRestService.resetPassword(apiUrl, data)
      .then((res: any) => res);
  }

  /**
  * Function to reset user password
  * @param apiUrl  api url name
  */
  public changeLocation(apiUrl: string, data: any) {
    return this.userRestService.changeLocation(apiUrl, data)
      .then((res: any) => res);
  }

    
  /**
   * Get notification list
   * @param apiUrl api url name
   */
  public getNotificationList(apiUrl: string, data: any) {
    return this.userRestService.getNotificationList(apiUrl, data)
      .then((res: any) => res);
  }

  /**
   * Function to clean guest db
   * @param apiUrl  api url name
   */
  public cleanGuestDb(apiUrl: string, data: any) {
    return this.userRestService.cleanGuestDb(apiUrl, data)
      .then((res: any) => res);
  }

  /**
   * Function to get all service schedule list
   * @param apiUrl  api url name
   */
  public getAllServiceScheduleList(apiUrl: string) {
    return this.userRestService.getAllServiceScheduleList(apiUrl)
      .then((res: any) => res);
  }

  /**
   * Function to get outlet list
   * @param apiUrl api url
   * @param data request data
   */
  public getMonthlySubscriptionTotal(apiUrl: string, data: any) {
    return this.userRestService.getMonthlySubscriptionTotal(apiUrl, data)
      .then((res: any) => res);
  }

  /**
   * Function to get outlet list
   * @param apiUrl api url
   * @param data request data
   */
  public getAnnualSubscriptionTotal(apiUrl: string, data: any) {
    return this.userRestService.getAnnualSubscriptionTotal(apiUrl, data)
      .then((res: any) => res);
  }

  /**
   * Function to get outlet list
   * @param apiUrl api url
   * @param data request data
   */
  public getPaidTreatmentTotal(apiUrl: string, data: any) {
    return this.userRestService.getPaidTreatmentTotal(apiUrl, data)
      .then((res: any) => res);
  }

  public createEmployeeUnavailability(apiUrl: string, data: any) {
    return this.userRestService.createInventory(apiUrl, data)
      .then((res: any) => res);
  }

  /**
   * Function to get member city
   * @param apiUrl api url
   * @param data request data
  */

  public getMemberCity(apiUrl: string) {
    return this.userRestService.getMembersCity(apiUrl)
      .then((res: any) => res);
  }


  public getMemberZipCode(apiUrl: string) {
    return this.userRestService.getMembersZipCode(apiUrl)
      .then((res: any) => res);
  }

  /**
   * Function to get outlet list
   * @param apiUrl api url
   * @param data request data
  */
  public getTherapistUsageReport(apiUrl: string, data: any) {
    return this.userRestService.getTherapistUsageReport(apiUrl, data)
      .then((res: any) => res);
  }

    /**
   * Update Detail
   * @param apiUrl api url name
   * @param data data request
   */
     public updateDetail(apiUrl: string, data: any) {
      return this.userRestService.updateDetail(apiUrl, data)
        .then((res: any) => res);
    }

    /**
    * Retry Member Payment
    * @param apiUrl api url name
    * @param data data request
    */
     public getMemberOutstandingTransaction(apiUrl: string, data: any) {
      return this.userRestService.retryPayment(apiUrl, data)
        .then((res: any) => res);
    }

    /**
    * Retry Member Payment
    * @param apiUrl api url name
    * @param data data request
    */
    public retryPayment(apiUrl: string, data: any) {
      return this.userRestService.retryPayment(apiUrl, data)
        .then((res: any) => res);
    }

    /**
    * Receive Member Payment
    * @param apiUrl api url name
    * @param data data request
    */
    public receivePayment(apiUrl: string, data: any) {
      return this.userRestService.receivePayment(apiUrl, data)
        .then((res: any) => res);
    }


    /**
     * Function to get all invite count list
     * @param apiUrl  api url name
    */
    public getTransactionsTotal(apiUrl: string, data: any) {
      return this.userRestService.getTransactionsTotal(apiUrl, data)
        .then((res: any) => res);
    }

}
