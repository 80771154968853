import {Constants } from '../../common/Constants';

export class UserApi {
    static sendInvitationUrl = Constants.baseUrl + 'nominated-persons/';
    static saveUserUrl = Constants.baseUrl + 'add-member-from-admin/';
    static getUserByIdUrl = Constants.baseUrl + 'user';
    static updateUserUrl = Constants.baseUrl + 'customer-update';
    static updateMemberEmailSubscriptionUrl = Constants.baseUrl + 'update-member-email-subscription';
    static updateEmployeeUrl = Constants.baseUrl + 'update-employee/';
    static getUserListUrl = Constants.baseUrl + 'users';
    static deleteUserUrl = Constants.baseUrl + 'user/';
    static deleteEmployeeUrl = Constants.baseUrl + 'employee/';
    static getOutletScheduleByOutletIdUrl = Constants.baseUrl + 'outlet-schedule-list/';
    static getEmployementTypeUrl = Constants.baseUrl + 'worker-type/';
    static saveEmployementTypeUrl = Constants.baseUrl + 'create-employee/';
    static getOutletsListUrl = Constants.baseUrl + 'outlets/';
    static getRolesListUrl = Constants.baseUrl + 'roles/';
    static customerInvitationUrl = Constants.baseUrl + 'create-customer-by-invitation/';
    static getRequestCustomerListUrl = Constants.baseUrl + 'requested-customers/';
    static getRequestStatusListUrl = Constants.baseUrl + 'accepted-rejected-customer-list/';
    static getInvitedCustomerListUrl = Constants.baseUrl + 'invited-customers/';
    static getCustomersListUrl = Constants.baseUrl + 'customers/';

    static getTrialMemberListWithPaginationUrl = Constants.baseUrl + 'trial-member-list-with-pagination/';
    static getTrialMembersCountUrl = Constants.baseUrl + 'trial-member-counts/';

    static getSubscribedTrialMemberListWithPaginationUrl = Constants.baseUrl + 'member/subscribed-trial-member-list-with-pagination/';

    static getUnsbscribedTrialMemberListWithPaginationUrl = Constants.baseUrl + 'member/unsubscribed-trial-member-list-with-pagination/';

    static getInactiveCustomersListUrl = Constants.baseUrl + 'blocked-customer-list/';
    static getEmployementListWithPaginationUrl = Constants.baseUrl + 'employees/';
    static acceptRejectCustomerUrl = Constants.baseUrl + 'accept-reject-customer/';
    static blockUnblockCustomerUrl = Constants.baseUrl + 'block-unblock-customer/';
    static frontendBaseurl = Constants.frontEndBaseUrl;
    static adminBaseUrl = Constants.adminBaseUrl;
    static getEmployeeByIdUrl = Constants.baseUrl + 'employee/';
    static getShiftListByScheduleIdUrl = Constants.baseUrl + 'shift-list-by-multiple-schedule/';
    static getOutletServiceListByOutletIdUrl = Constants.baseUrl + 'service-list/';
    static getMenuByRole = Constants.baseUrl + 'admin-menu';
    static baseUrlFrontend = Constants.frontEndBaseUrl;
    static OutletListUrl = Constants.baseUrl + 'company-outlets/';
    static OutletListV2Url = Constants.baseUrl + 'location/list-v2/';
    static getEmployeeListByOutletIdUrl = Constants.baseUrl + 'employees-by-outlet/';
    static saveEmployeeTimingsByOutletIdUrl = Constants.baseUrl + 'create-employee-shift-time/';
    static getEmployeeTimingsByOutletIdUrl = Constants.baseUrl + 'get-employee-shift-time-by-employee-id/';
    static getEmployeeUserStatusByUserIdUrl = Constants.baseUrl + 'user-status-list/';
    static resendInvitationUrl = Constants.baseUrl + 'resend-invitation/';
    static getAllStateListUrl = Constants.baseUrl + 'get-all-states/';
    static getAllCitiesListUrl = Constants.baseUrl + 'get-cities/';
    static saveEventUrl = Constants.baseUrl + 'create-event/';
    static bookingCustomerDetail = Constants.baseUrl + 'scheduled-service-booking-customers/';
    static bookingUpdateCustomerDetail = Constants.baseUrl + 'update-customer-booked-service-attendance/';
    static guestInvitationUrl = Constants.baseUrl + 'create-guest-by-invitation/';
    static getGusetListUrl = Constants.baseUrl + 'guest-list-with-pagination/';
    static getServiceListUrl = Constants.baseUrl + 'human-touch-services/';
    static getAllCustomerCountUrl = Constants.baseUrl + 'customer-totals/';
    static getAllInviteCountUrl = Constants.baseUrl + 'invited-customer-totals/';
    static getEmployeeServicesByEmployeeIdUrl = Constants.baseUrl + 'employee-services/';
    static getEmployeeShiftScheduleUrl = Constants.baseUrl + 'get-employee-shift-schedule/';
    static updateEmployeeTimingsByOutletIdUrl = Constants.baseUrl + 'update-employee-shift-time/';
    static nominatedCustomersListUrl = Constants.baseUrl + 'nominated-persons/';
    static getNotificationCustomersListUrl = Constants.baseUrl + 'customer-list/';
    static getNotificationProvidersListUrl = Constants.baseUrl + 'employee-list/';
    static saveEmailNotificationUrl = Constants.baseUrl + 'email-notification-create/';
    static getEmailNotificationListWithPaginationUrl = Constants.baseUrl + 'email-notification-list-with-pagination/';
    static getEmailNotificationMembersList = Constants.baseUrl + 'email-notification-users-list/';
    static getEmailNotificationUserStatusListUrl = Constants.baseUrl + 'user-status/';
    static getEmailNotificationUserStatusListV2Url = Constants.baseUrl + 'user-status-v2/';
    static getEmployeeTimingSlotsByServiceUrl = Constants.baseUrl + 'employee-scheduling-calendar/';
    static getGuestByGuestIdUrl = Constants.baseUrl + 'guest';
    static updateGuestUrl = Constants.baseUrl + 'update-invited-guest';
    static getSubstituteDetail = Constants.baseUrl + 'subsitute-employee-list/';
    static updateSubstituteBySlotId = Constants.baseUrl + 'update-subsitute-in-slot';
    static frontDeskBookingListUrl = Constants.baseUrl + 'front-desk-booking-list/';
    static getEmailNotificationServiceListUrl = Constants.baseUrl + 'services/';
    static getGuestListUrl = Constants.baseUrl + 'guest-list/';
    static getGuestListByOutletUrl = Constants.baseUrl + 'guest-list-by-outlet/';
    static getServiceListByOutletIdUrl = Constants.baseUrl + 'outlet-services/';
    static getProviderListByServiceIdUrl = Constants.baseUrl + 'web/service-employee-list';
    static getMemberListByOutletIdUrl = Constants.baseUrl + 'customers-by-outlet/';
    static getMemberListByEventIdUrl = Constants.baseUrl + 'customers-by-event/';
    static slotListByOutletDateUrl = Constants.baseUrl + 'web/outlet-services-slots-by-date/';
    static slotListByOutletDateV2Url = Constants.baseUrl + 'web/outlet-services-slots-by-date-v2/';
    static createBookingSlotUrl = Constants.baseUrl + 'book-schedule-service/';
    static getAttendiesListBySlotId = Constants.baseUrl + 'scheduled-service-booking-customers/';
    static removeAttendiesByBookingId = Constants.baseUrl + 'cancel-schedule-service-by-admin/';
    static cancelSlotBySlotId = Constants.baseUrl + 'cancel-class/';
    static userTransactionHistoryUrl = Constants.baseUrl + 'transaction-pagination-history-by-user/';
    static userPendingTransactionHistoryUrl = Constants.baseUrl + 'web/member/pending-transaction-list-pagination/';
    static guestTransactionHistoryUrl = Constants.baseUrl + 'guest-transaction-list-with-pagination/';
    static employeeTransactionHistoryUrl = Constants.baseUrl + 'web/employee-transaction-history/';
    static getUpcomingBookingListUrl = Constants.baseUrl + 'front-desk-dashbord-upcoming-booking-list/';
    static getPastBookingListUrl = Constants.baseUrl + 'front-desk-dashbord-past-booking-list/';

    static getAdminCheckInUpcomingBookingListUrl = Constants.baseUrl + 'admin-check-in-upcoming-booking-list/';

    static getAdminCheckInPastBookingListUrl = Constants.baseUrl + 'admin-check-in-past-booking-list/';

    static emailHostListUrl = Constants.baseUrl + 'email-host-list/';
    static getWaitingListBySlotId = Constants.baseUrl + 'schedule-service-booking-waitinglist/';
    static removeWaitingUserByBookingId = Constants.baseUrl + 'remove-from-waitlist-admin/';
    static resetPasswordUrl = Constants.baseUrl + 'web/reset-password/';
    static changeLocationUrl = Constants.baseUrl + 'web/member/change-location/';
    static outletTimingByDate = Constants.baseUrl + 'get-outlet-timing/';
    static NotificationListUrl = Constants.baseUrl + 'beacon/logged-in-customer/';
    static cleanGuestDbUrl = Constants.baseUrl + 'clean-guest-data/';
    static getAllServiceScheduleListUrl = Constants.baseUrl + 'get-service-schedule-by-service/';
    static getPausedCustomersListUrl = Constants.baseUrl + 'paused-customer-list/';

    // Manager URL
    static getManagerListWithPaginationUrl = Constants.baseUrl + 'managers/';
    static addManagerUrl = Constants.baseUrl + 'add-manager-from-admin/';
    static getManagerUrl = Constants.baseUrl + 'manager/';
    static updateManagerUrl = Constants.baseUrl + 'update-manager/';
    static deleteManagerUrl = Constants.baseUrl + 'manager/';

    // Payment Report
    static getStripePaymentDetailUrl = Constants.baseUrl + 'stripe-payment-detail/';
    
    static getMonthlySubscriptionListWithPaginationUrl = Constants.baseUrl + 'monthly-subscription-detail/';
    static getMonthlySubscriptionListTotalsUrl = Constants.baseUrl + 'monthly-subscription-detail-total/';

    static getAnnualSubscriptionListWithPaginationUrl = Constants.baseUrl + 'annual-subscription-detail/';
    static getAnnualSubscriptionListTotalsUrl = Constants.baseUrl + 'annual-subscription-detail-total/';

    static getPaidTreatmentListWithPaginationUrl = Constants.baseUrl + 'paid-treatment-detail/';
    static getPaidTreatmentTotalsUrl = Constants.baseUrl + 'paid-treatment-detail-total/';

    // static getMonthlySubscriptionTotalUrl = Constants.baseUrl + 'monthly-subscription-total/';
    // static getAnnualSubscriptionTotalUrl = Constants.baseUrl + 'annual-subscription-total/';
    // static getPaidTreatmentTotalUrl = Constants.baseUrl + 'paid-treatment-total/';


    static getEmployeeUnavailabilityListWithPaginationUrl = Constants.baseUrl + 'get-employee-unavailability-list/';
    static createEmployeeUnavailabilityURL = Constants.baseUrl + 'employee-unavailability/create/';

    static getMemberGeoReportWithPaginationUrl = Constants.baseUrl + 'member-geo-report/';
    static getMemberListGeoReportUrl = Constants.baseUrl + 'geo-member-list/';
    static getMembersCityUrl = Constants.baseUrl + 'members-city/';
    static getMembersZipCodeUrl = Constants.baseUrl + 'members-zipcode/';

    static setMemberDefaultLocation = Constants.baseUrl + 'member/update-default-location/';

    // static getMemberBySearchUrl = Constants.baseUrl + 'member/multisearch-with-pagination/';
    static getMemberBySearchUrl = Constants.baseUrl + 'member/search-with-pagination/';
    static receiveMemberPaymentUrl = Constants.baseUrl + 'member/payment-received/';
    static deleteMemberPaymentUrl = Constants.baseUrl + 'member/payment-delete/';
    static getMemberPaymentTransactionUrl = Constants.baseUrl + 'web/get-member-pending-transaction/';
    static retryMemberPaymentByIdUrl = Constants.baseUrl + 'member/retry-payment-by-id/';
    static retryMemberPaymentUrl = Constants.baseUrl + 'member/retry-payment/';
    static memberOutstandingListUrl = Constants.baseUrl + 'pending-transaction-list-with-pagination/';
    static getTransactionsTotalUrl = Constants.baseUrl + 'transaction-history-total-by-user/';
    

}
