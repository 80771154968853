// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // --------- Live --------
  apiUrl: 'https://backend.arthamindbodysoul.com/api/',
  baseUrl: 'https://backend.arthamindbodysoul.com/api/',
  loginBaseUrl: 'https://backend.arthamindbodysoul.com/api/',
  // frontendBaseUrl: 'https://artha.la/',
  frontendBaseUrl: 'https://join.arthamindbodysoul.com/',
  adminBaseUrl: 'http://admin.arthamindbodysoul.com/',
  mediaUrl: 'https://backend.arthamindbodysoul.com',
  scityStripePublishableKey: 'pk_live_51NJStpJBHV2EiRiPC6qrCrchLL4HdMzSJ2Jc1kA5YhtNtlth9gExkNyHdZoNQ42QmLrelG6dzUTtr9TCLCwRlP9900laGxSE8I',
  westLAStripePublishableKey: 'pk_live_51NJStpJBHV2EiRiPC6qrCrchLL4HdMzSJ2Jc1kA5YhtNtlth9gExkNyHdZoNQ42QmLrelG6dzUTtr9TCLCwRlP9900laGxSE8I',
  weHoStripePublishableKey: 'pk_live_51NJStpJBHV2EiRiPC6qrCrchLL4HdMzSJ2Jc1kA5YhtNtlth9gExkNyHdZoNQ42QmLrelG6dzUTtr9TCLCwRlP9900laGxSE8I',
  goldGYMStripePublishableKey: 'pk_live_51NJStpJBHV2EiRiPC6qrCrchLL4HdMzSJ2Jc1kA5YhtNtlth9gExkNyHdZoNQ42QmLrelG6dzUTtr9TCLCwRlP9900laGxSE8I'

  // --------- Live Dev--------
  // apiUrl: 'http://107.23.179.250:8000/api/',
  // baseUrl: 'http://107.23.179.250:8000/api/',
  // loginBaseUrl: 'http://107.23.179.250:8000/api/',
  // frontendBaseUrl: 'https://join.arthamindbodysoul.com/',
  // adminBaseUrl: 'http://admin.arthamindbodysoul.com/',
  // mediaUrl: 'http://107.23.179.250:8000',
  // scityStripePublishableKey: 'pk_live_51NJStpJBHV2EiRiPC6qrCrchLL4HdMzSJ2Jc1kA5YhtNtlth9gExkNyHdZoNQ42QmLrelG6dzUTtr9TCLCwRlP9900laGxSE8I',
  // weHoStripePublishableKey: 'pk_live_MWFNl7K3EJ0xgX6VKt8zF8Bx00YVyGCggD',
  // goldGYMStripePublishableKey: 'pk_live_MWFNl7K3EJ0xgX6VKt8zF8Bx00YVyGCggD'

  // --------- QA --------
  // apiUrl: 'http://np.infinitywebtechnologies.com:8061/api/',
  // baseUrl: 'http://np.infinitywebtechnologies.com:8061/api/',
  // loginBaseUrl: 'http://np.infinitywebtechnologies.com:8061/api/',
  // frontendBaseUrl: 'http://stgsd.appsndevs.com:9040/spafrontend/',
  // adminBaseUrl: 'http://stgsd.appsndevs.com:9040/spaadmin/',
  // mediaUrl: 'http://np.infinitywebtechnologies.com:8061',
  // scityStripePublishableKey: 'pk_test_51MqW3tFQZmE91DAeBoFMpz1VhdQIuhdGmCpMQovmbLA4MQBFuZGiXEN36DaQuOlh5XNoR7D6k3DndOcQVoyapnrQ00mjC7cRY2',
  // westLAStripePublishableKey: 'pk_test_51PiVyQRtvkIfDCu8snzQakg5m09EwQOjS4Td9xUzyIhQYH1Nm64XOHKyJ0QNu2Wo0EpYIiwD63lQU8PGmWB2lRPL00Y3n4Yqbu',
  // weHoStripePublishableKey: 'pk_test_51NhnceKHqVw2SGficnMWROeda2teVMxKOeqT824HN4UYQ7oqG0Lk3Ph5VFmiWfUNxwP9XwjBBPJ1PBcfcMTaXw5i00c5ad1GFL',
  // goldGYMStripePublishableKey: 'pk_test_51MtTmzJuBynkueDHuQFsay0ORc9p3xR2G96uWjcdtcBoxZRLZTLigE3jfN1fG9VNk9fILiH5szG8CJpe0MH0tTjO00keEjf4qk'


  // --------- Dev New --------
  // apiUrl: 'http://spadev.appsndevs.com/api/',
  // baseUrl: 'http://spadev.appsndevs.com/api/',
  // loginBaseUrl: 'http://spadev.appsndevs.com/api/',
  // frontendBaseUrl: 'http://stgsd.appsndevs.com:9040/spafrontend/',
  // adminBaseUrl: 'http://stgsd.appsndevs.com:9040/spaadmin/',
  // mediaUrl: 'http://spadev.appsndevs.com',
  // scityStripePublishableKey: 'pk_test_51MqW3tFQZmE91DAeBoFMpz1VhdQIuhdGmCpMQovmbLA4MQBFuZGiXEN36DaQuOlh5XNoR7D6k3DndOcQVoyapnrQ00mjC7cRY2',
  // westLAStripePublishableKey: 'pk_test_51PiVyQRtvkIfDCu8snzQakg5m09EwQOjS4Td9xUzyIhQYH1Nm64XOHKyJ0QNu2Wo0EpYIiwD63lQU8PGmWB2lRPL00Y3n4Yqbu',
  // weHoStripePublishableKey: 'pk_test_51NhnceKHqVw2SGficnMWROeda2teVMxKOeqT824HN4UYQ7oqG0Lk3Ph5VFmiWfUNxwP9XwjBBPJ1PBcfcMTaXw5i00c5ad1GFL',
  // goldGYMStripePublishableKey: 'pk_test_51MtTmzJuBynkueDHuQFsay0ORc9p3xR2G96uWjcdtcBoxZRLZTLigE3jfN1fG9VNk9fILiH5szG8CJpe0MH0tTjO00keEjf4qk'


    // --------- Dev Old --------
  // apiUrl: 'http://np.infinitywebtechnologies.com:9050/api/',
  // baseUrl: 'http://np.infinitywebtechnologies.com:9050/api/',
  // loginBaseUrl: 'http://np.infinitywebtechnologies.com:9050/api/',
  // frontendBaseUrl: 'http://stgsd.appsndevs.com:9040/spafrontenddev/',
  // adminBaseUrl: 'http://stgsd.appsndevs.com:9040/spaadmindev/',
  // mediaUrl: 'http://np.infinitywebtechnologies.com:9050',
  // scityStripePublishableKey: 'pk_test_51MqW3tFQZmE91DAeBoFMpz1VhdQIuhdGmCpMQovmbLA4MQBFuZGiXEN36DaQuOlh5XNoR7D6k3DndOcQVoyapnrQ00mjC7cRY2',
  // westLAStripePublishableKey: 'pk_test_51PiVyQRtvkIfDCu8snzQakg5m09EwQOjS4Td9xUzyIhQYH1Nm64XOHKyJ0QNu2Wo0EpYIiwD63lQU8PGmWB2lRPL00Y3n4Yqbu',
  // weHoStripePublishableKey: 'pk_test_51NhnceKHqVw2SGficnMWROeda2teVMxKOeqT824HN4UYQ7oqG0Lk3Ph5VFmiWfUNxwP9XwjBBPJ1PBcfcMTaXw5i00c5ad1GFL',
  // goldGYMStripePublishableKey: 'pk_test_51MtTmzJuBynkueDHuQFsay0ORc9p3xR2G96uWjcdtcBoxZRLZTLigE3jfN1fG9VNk9fILiH5szG8CJpe0MH0tTjO00keEjf4qk'


  // --------- Local --------
  // apiUrl: 'http://127.0.0.1:8000/api/',
  // baseUrl: 'http://127.0.0.1:8000/api/',
  // loginBaseUrl: 'http://127.0.0.1:8000/api/',
  // frontendBaseUrl: 'http://stgsp.appsndevs.com:9041/spafrontend/',
  // adminBaseUrl: 'http://stgsp.appsndevs.com:9041/spaadmin/',
  // mediaUrl: 'http://127.0.0.1:8000',
  // scityStripePublishableKey: 'pk_test_51MqW3tFQZmE91DAeBoFMpz1VhdQIuhdGmCpMQovmbLA4MQBFuZGiXEN36DaQuOlh5XNoR7D6k3DndOcQVoyapnrQ00mjC7cRY2',
  // westLAStripePublishableKey: 'pk_test_51PiVyQRtvkIfDCu8snzQakg5m09EwQOjS4Td9xUzyIhQYH1Nm64XOHKyJ0QNu2Wo0EpYIiwD63lQU8PGmWB2lRPL00Y3n4Yqbu',
  // weHoStripePublishableKey: 'pk_test_51NhnceKHqVw2SGficnMWROeda2teVMxKOeqT824HN4UYQ7oqG0Lk3Ph5VFmiWfUNxwP9XwjBBPJ1PBcfcMTaXw5i00c5ad1GFL',
  // goldGYMStripePublishableKey: 'pk_test_51MtTmzJuBynkueDHuQFsay0ORc9p3xR2G96uWjcdtcBoxZRLZTLigE3jfN1fG9VNk9fILiH5szG8CJpe0MH0tTjO00keEjf4qk'


};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
