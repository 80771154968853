import { Injectable } from '@angular/core';
import { Headers, Http, RequestOptions} from '@angular/http';
import { ApiService } from '../../../common/services/rest-api/api.service';
import 'rxjs/add/operator/toPromise';
import 'rxjs/Rx';

@Injectable()
export class UserRestService {
  constructor(private apiService: ApiService) {
  }

  /**
   * create customer
   * @param apiUrl api url
   * @param data request data
   */
  public createUser(apiUrl: string, data: any) {
    return this.apiService.postApi(apiUrl, data)
      .toPromise()
      .then((res) => res);
  }

  /**
   * Update User data by id
   * @param apiUrl api url
   * @param data request data
   */
  public updateUser(apiUrl: string, data: any) {
    return this.apiService.putApi(apiUrl, data)
      .toPromise()
      .then((res) => res);
  }

  /**
   * Get User by id
   * @param apiUrl api url
   * @param data request data
   */
  public getUserById(apiUrl: string) {
    return this.apiService.getApi(apiUrl)
      .toPromise()
      .then((res) => res);
  }

  /**
   * Get City by stateId
   * @param apiUrl api url
   * @param data request data
   */
  public getCityByStateId(apiUrl: string) {
    return this.apiService.getApi(apiUrl)
      .toPromise()
      .then((res) => res);
  }

  /**
   * Get menu by role
   * @param apiUrl api url
   * @param data request data
   */
  public getMenuByRole(apiUrl: string) {
    return this.apiService.getApi(apiUrl)
      .toPromise()
      .then((res) => res);
  }

  /**
   * Function to get all Users
   */

  public getUserList(apiUrl: string) {
    return this.apiService.getApi(apiUrl)
      .toPromise()
      .then((res) => res);
  }

  /**
   * Function to get all Users
   */

  public getAllStateList(apiUrl: string) {
    return this.apiService.getApi(apiUrl)
      .toPromise()
      .then((res) => res);
  }

  /**
   * Function delete User by id
   * @param id user id
   */

  public delete(apiUrl: string) {
    return this.apiService.delete(apiUrl)
      .toPromise()
      .then((res) => res);
  }

  /**
   * Function to get all empolyee type for scheduleId
   */

  public getEmployementTypeByScheduleId(apiUrl: string) {
    return this.apiService.getApi(apiUrl )
      .toPromise()
      .then((res) => res);
  }

  /**
   * Function to get all outlet schedule by outlet id
   */

  public getScheduleByOutletId(apiUrl: string) {
    return this.apiService.getApi(apiUrl )
      .toPromise()
      .then((res) => res);
  }

  /**
   * Function to get all outlet service by outletId
   */

  public getOutletServiceByOutletId(apiUrl: string) {
    return this.apiService.getApi(apiUrl )
      .toPromise()
      .then((res) => res);
  }

  /**
   * Function to get all outlet schedule by outlet id
   */

  public getShiftListBySchedule(apiUrl: string, data: any) {
    return this.apiService.postApi(apiUrl, data )
      .toPromise()
      .then((res) => res);
  }

  /**
   * Function to send invitation to user
   * @param apiUrl api url
   * @param data request data
   */

  public sendInvitation(apiUrl: string, data: any) {
    return this.apiService.postApi(apiUrl, data)
      .toPromise()
      .then((res) => res);
  }

  /**
   * Function to check email exist or not
   * @param apiUrl api url
   * @param data request data
   */

  public checkEmailExist(apiUrl: string, data: any) {
    return this.apiService.postApi(apiUrl, data)
      .toPromise()
      .then((res) => res);
  }

  /**
   * Function to complete registration
   * @param apiUrl api url
   * @param data request data
  */
  public validateMemberTrailCoupon(apiUrl: string, data: any) {
    return this.apiService.postApi(apiUrl, data)
      .toPromise()
      .then((res) => res);
  }

  /**
   * get role list
   * @param apiUrl api url
   */

  public getRoleList(apiUrl: string) {
    return this.apiService.getApi(apiUrl)
      .toPromise()
      .then((res) => res);
  }

  /**
   * accept reject user
   * @param apiUrl api url
   */
  public acceptRejectUser(apiUrl: string, data: any) {
    return this.apiService.putApi(apiUrl, data)
      .toPromise()
      .then((res) => res);
  }

  /**
   * Block unbloack user
   * @param apiUrl api url
   */
  public blockUnblockUser(apiUrl: string, data: any) {
    return this.apiService.putApi(apiUrl, data)
      .toPromise()
      .then((res) => res);
  }

  /**
   * create employee
   * @param apiUrl api url
   * @param data request data
   */
  public createEmployee(apiUrl: string, data: any) {
    return this.apiService.sendFormData(apiUrl, data)
      .toPromise()
      .then((res) => res);
  }

  /**
   * Update employee data by id
   * @param apiUrl api url
   * @param data request data
   */
  public updateEmployee(apiUrl: string, data: any) {
    return this.apiService.sendFormDataPutApi(apiUrl, data)
      .toPromise()
      .then((res) => res);
  }

  /**
   * Get outlet list
   * @param apiUrl api url
   */
  public getOutletList(apiUrl: string) {
    return this.apiService.getApi(apiUrl)
      .toPromise()
      .then((res) => res);
  }

  /**
   * Function to get all employye list by outlet id
   */

  public getEmployeeListByOutletId(apiUrl: string) {
    return this.apiService.getApi(apiUrl)
      .toPromise()
      .then((res) => res);
  }

  /**
   * Function to get all user status list by outlet id
   */

  public getUserStatusById(apiUrl: string) {
    return this.apiService.getApi(apiUrl)
      .toPromise()
      .then((res) => res);
  }

  /**
   * Function to craete event
   * @param apiUrl api url
   * @param data request data
   */

  public createEvent(apiUrl: string, data: any) {
    return this.apiService.postApi(apiUrl, data)
      .toPromise()
      .then((res) => res);
  }

  /**
   * Function to get all employye detail by slot id
   */

  public getEmployeeDetailBySlottId(apiUrl: string) {
    return this.apiService.getApi(apiUrl)
      .toPromise()
      .then((res) => res);
  }

  /**
   * Update customer booking attendence
   * @param apiUrl api url
   * @param data request data
   */
  public updateCustomerBookingAttendence(apiUrl: string, data: any) {
    return this.apiService.postApi(apiUrl, data)
      .toPromise()
      .then((res) => res);
  }

  /**
   * Function to create invitation to guest
   * @param apiUrl api url
   * @param data request data
   */

  public createGuestInvitation(apiUrl: string, data: any) {
    return this.apiService.postApi(apiUrl, data)
      .toPromise()
      .then((res) => res);
  }

  /**
   * Update guset data by id
   * @param apiUrl api url
   * @param data request data
   */
  public updateGuestInvitation(apiUrl: string, data: any) {
    return this.apiService.putApi(apiUrl, data)
      .toPromise()
      .then((res) => res);
  }

  /**
   * Function to get all customer count
   */
  public getAllCustomerCount(apiUrl: string, data: any) {
    return this.apiService.postApi(apiUrl, data)
      .toPromise()
      .then((res) => res);
  }

   /**
   * Function to get all invite count
   */
  public getAllInviteCount(apiUrl: string, data: any) {
    return this.apiService.postApi(apiUrl, data)
      .toPromise()
      .then((res) => res);
  }

  /**
   * Function to get all invite count
  */
  public getTrailMemberCount(apiUrl: string, data: any) {
    return this.apiService.postApi(apiUrl, data)
      .toPromise()
      .then((res) => res);
  }

  /**
   * Function to get all employye services list by employee id
   */

  public getServicesListByEmployeeId(apiUrl: string) {
    return this.apiService.getApi(apiUrl)
      .toPromise()
      .then((res) => res);
  }

  /**
   * get employee timing details
   * @param apiUrl api url
   * @param data request data
   */
  public getEmployeeTimingDetails(apiUrl: string, data: any) {
    return this.apiService.postApi(apiUrl, data)
      .toPromise()
      .then((res) => res);
  }

  /**
   * Get nominated customer list
   * @param apiUrl api url
   */
  public getNominatedCustomerList(apiUrl: string) {
    return this.apiService.getApi(apiUrl)
      .toPromise()
      .then((res: any) => res);
  }

  /**
   * send email notification
   * @param apiUrl api url
   * @param data request data
   */
  public sendNotification(apiUrl: string, data: any) {
    return this.apiService.postApi(apiUrl, data)
      .toPromise()
      .then((res) => res);
  }

   /**
   * Function to get all member deatils send email notification 
   */

  public getMemberDetails(apiUrl: string, data: any) {
    return this.apiService.postApi(apiUrl, data)
      .toPromise()
      .then((res) => res);
  }

  /**
   * Function to create inventory
   * @param apiUrl api url
   * @param data request data
   */

  public createInventory(apiUrl: string, data: any) {
    return this.apiService.postApi(apiUrl, data)
      .toPromise()
      .then((res) => res);
  }

  /**
   * Get Inventory by id
   * @param apiUrl api url
   * @param data request data
   */
  public getInventoryById(apiUrl: string) {
    return this.apiService.getApi(apiUrl)
      .toPromise()
      .then((res) => res);
  }

  /**
   * Update inventory data by id
   * @param apiUrl api url
   * @param data request data
   */
  public updateInventory(apiUrl: string, data: any) {
    return this.apiService.putApi(apiUrl, data)
      .toPromise()
      .then((res) => res);
  }

  /**
   * Function to create inventory pos
   * @param apiUrl api url
   * @param data request data
   */

  public createInventoryPos(apiUrl: string, data: any) {
    return this.apiService.postApi(apiUrl, data)
      .toPromise()
      .then((res) => res);
  }

   /**
   * Function to get all Inventory Items
   */

  public getInventoryItemsList(apiUrl: string) {
    return this.apiService.getApi(apiUrl)
      .toPromise()
      .then((res) => res);
  }

  /**
   * Function to get all Inventory Items
   */

      public getMemberPOSDataList(apiUrl: string) {
        return this.apiService.getApi(apiUrl)
          .toPromise()
          .then((res) => res);
      }

  /**
   * Function to get all employee slots
   */

  public getEmployeeTimingSlotsByService(apiUrl: string, data: any) {
    return this.apiService.postApi(apiUrl, data)
      .toPromise()
      .then((res) => res);
  }

  /**
   * Get Guest by guest id
   * @param apiUrl api url
   * @param data request data
   */
  public getGuestByGuestId(apiUrl: string) {
    return this.apiService.getApi(apiUrl)
      .toPromise()
      .then((res) => res);
  }

  /**
   * Update substitute
   * @param apiUrl api url
   * @param data request data
   */
  public updateSubstitute(apiUrl: string, data: any) {
    return this.apiService.putApi(apiUrl, data)
      .toPromise()
      .then((res) => res);
  }

  /**
   * Function to get outlet service booking list
   */

  public getOutletServiceBookingList(apiUrl: string, data: any) {
    return this.apiService.postApi(apiUrl, data)
      .toPromise()
      .then((res) => res);
  }

  /**
   * Function to get all outlet service by outlet id
   */

  public getServiceListByOutletId(apiUrl: string, data: any) {
    return this.apiService.postApi(apiUrl, data )
      .toPromise()
      .then((res) => res);
  }

  /**
   * Function to get all service by service id
   */

  public getProviderListByServiceId(apiUrl: string) {
    return this.apiService.getApi(apiUrl)
      .toPromise()
      .then((res) => res);
  }

  /**
   * Function to get booking slots list
   */

  public getBookingSlots(apiUrl: string, data: any) {
    return this.apiService.postApi(apiUrl, data)
      .toPromise()
      .then((res) => res);
  }

  /**
   * Function to create booking slots
   */

  public createBookSlots(apiUrl: string, data: any) {
    return this.apiService.postApi(apiUrl, data)
      .toPromise()
      .then((res) => res);
  }

  /**
   * Function to remove attendies by booking id
   * @param apiUrl  requested api url
   */
  public removeAttendiesByBookingId(apiUrl: string, data: any) {
    return this.apiService.putApi(apiUrl, data)
      .toPromise()
      .then((res) => res);
  }

  /**
   * Function to cancel slot by slot id
   * @param apiUrl  requested api url
   */
  public cancelSlotBySlotId(apiUrl: string) {
    return this.apiService.delete(apiUrl)
      .toPromise()
      .then((res) => res);
  }

  /**
   * Function to get upcoming booking list
   */
  public getUpcomingBookingList(apiUrl: string, data: any) {
    return this.apiService.postApi(apiUrl, data)
      .toPromise()
      .then((res) => res);
  }

  /**
   * Function to get upcoming booking list
  */
  public getPastBookingList(apiUrl: string, data: any) {
    return this.apiService.postApi(apiUrl, data)
      .toPromise()
      .then((res) => res);
  }

  /**
   * Get email host list
   * @param apiUrl api url
   */
  public getEmailHostList(apiUrl: string) {
    return this.apiService.getApi(apiUrl)
      .toPromise()
      .then((res: any) => res);
  }

  /**
   * Function to remove waitlist by booking id
   * @param apiUrl  requested api url
   */
  public removeWaitingUserByBookingId(apiUrl: string, data: any) {
    return this.apiService.postApi(apiUrl, data)
      .toPromise()
      .then((res) => res);
  }

  /**
   * reset user password
   * @param apiUrl api url
   */
  public resetPassword(apiUrl: string, data: any) {
    return this.apiService.putApi(apiUrl, data)
      .toPromise()
      .then((res) => res);
  }

    /**
   * reset user password
   * @param apiUrl api url
   */
    public changeLocation(apiUrl: string, data: any) {
      return this.apiService.putApi(apiUrl, data)
        .toPromise()
        .then((res) => res);
    }

  /**
   * Function to get notification list
   */
  public getNotificationList(apiUrl: string, data: any) {
    return this.apiService.postApi(apiUrl, data)
      .toPromise()
      .then((res) => res);
  }

  /**
   * Function to get clean Guest Db
   */
  public cleanGuestDb(apiUrl: string, data: any) {
    return this.apiService.postApi(apiUrl, data)
      .toPromise()
      .then((res) => res);
  }

  /**
   * Function to get all service schedule list
   */

  public getAllServiceScheduleList(apiUrl: string) {
    return this.apiService.getApi(apiUrl)
      .toPromise()
      .then((res) => res);
  }

  /**
   * Function to get all service schedule list
   */
  public getMonthlySubscriptionTotal(apiUrl: string, data: any) {
    return this.apiService.postApi(apiUrl, data)
      .toPromise()
      .then((res) => res);
  }
    
  /**
   * Function to get all service schedule list
   */
   public getAnnualSubscriptionTotal(apiUrl: string, data: any) {
    return this.apiService.postApi(apiUrl, data)
      .toPromise()
      .then((res) => res);
  }

  /**
   * Function to get all service schedule list
   */
   public getPaidTreatmentTotal(apiUrl: string, data: any) {
    return this.apiService.postApi(apiUrl, data)
      .toPromise()
      .then((res) => res);
  }

    /**
   * Function to create Employee Unavailability
   * @param apiUrl api url
   * @param data request data
   */

     public createEmployeeUnavailability(apiUrl: string, data: any) {
      return this.apiService.postApi(apiUrl, data)
        .toPromise()
        .then((res) => res);
    }

    /**
     * Get Member city
     * @param apiUrl api url
     */
    public getMembersCity(apiUrl: string) {
      return this.apiService.getApi(apiUrl)
        .toPromise()
        .then((res) => res);
    }

    /**
     * Get Member city
     * @param apiUrl api url
     */
         public getMembersZipCode(apiUrl: string) {
          return this.apiService.getApi(apiUrl)
            .toPromise()
            .then((res) => res);
        }

    /**
    * Function to get all service schedule list
    */
    public getTherapistUsageReport(apiUrl: string, data: any) {
     return this.apiService.postApi(apiUrl, data)
      .toPromise()
      .then((res) => res);
    }

  /**
   * Update Detail
   * @param apiUrl api url
   * @param data request data
  */
  public updateDetail(apiUrl: string, data: any) {
    return this.apiService.postApi(apiUrl, data)
      .toPromise()
      .then((res) => res);
  }

  /**
  * Retry Member Payment
  * @param apiUrl api url name
  * @param data data request
  */
   public getMemberOutstandingTransaction(apiUrl: string, data: any) {
    return this.apiService.postApi(apiUrl, data)
      .toPromise()
      .then((res) => res);
  }

  /**
  * Retry Member Payment
  * @param apiUrl api url name
  * @param data data request
  */
  public retryPayment(apiUrl: string, data: any) {
    return this.apiService.postApi(apiUrl, data)
      .toPromise()
      .then((res) => res);
  }

  /**
  * Receive Member Payment
  * @param apiUrl api url name
  * @param data data request
  */
  public receivePayment(apiUrl: string, data: any) {
    return this.apiService.postApi(apiUrl, data)
      .toPromise()
      .then((res) => res);
  }

  /**
   * Function to get all invite count
  */
    public getTransactionsTotal(apiUrl: string, data: any) {
    return this.apiService.postApi(apiUrl, data)
      .toPromise()
      .then((res) => res);
  }

}
